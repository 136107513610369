import api from '~/plugins/api'

export const state = () => ({
  list: [],
  listOfCurrentSolution: []
})

export const actions = {
  async addAnswerForScenario({ commit, rootState }, payload) {
    const newAnswer = await api.createEntry('answers', {
      user: rootState.auth.user.id,
      solution: payload.solutionId,
      subcriterion: payload.subCriterion,
      scenarios: payload.scenarios
    })
    return newAnswer
  },

  updateAnswer({ commit, dispatch }, { answerId, field, value }) {
    dispatch('autoSave', null, { root: true })
    // commit('updateAnswer', { field, value })
    return api.updateEntry('answers', Number(answerId), {
      [field]: value
    })
  },

  setTextAnswer({ commit, rootState }, payload) {
    if (payload.answer)
      api.updateEntry('answers', payload.answer.id, {
        textAnswer: payload.textAnswer,
        user: rootState.auth.user.id
      })
    else {
      api.createEntry('answers', {
        user: rootState.auth.user.id,
        solution: payload.solutionId,
        subcriterion: payload.subCriterion.id,
        textAnswer: payload.textAnswer
      })
    }
  },

  setBooleanAnswer({ commit, rootState }, payload) {
    if (payload.answer)
      api.updateEntry('answers', payload.answer.id, {
        booleanAnswer: payload.booleanAnswer,
        user: rootState.auth.user.id
      })
    else {
      api.createEntry('answers', {
        user: rootState.auth.user.id,
        solution: payload.solutionId,
        subcriterion: payload.subCriterion.id,
        booleanAnswer: payload.booleanAnswer
      })
    }
  },

  setUnit({ commit, rootState }, payload) {
    if (payload.answer)
      api.updateEntry('answers', payload.answer.id, { unit: payload.unit })
    else {
      api.createEntry('answers', {
        user: rootState.auth.user.id,
        solution: payload.solutionId,
        subcriterion: payload.subCriterion.id,
        unit: payload.unit
      })
    }
  },

  setUnitValue({ commit, rootState }, payload) {
    if (payload.answer)
      api.updateEntry('answers', payload.answer.id, {
        unitValue: payload.unitValue
      })
    else {
      api.createEntry('answers', {
        user: rootState.auth.user.id,
        solution: payload.solutionId,
        subcriterion: payload.subCriterion.id,
        unitValue: payload.unitValue
      })
    }
  },

  setEvidenceText({ commit, rootState }, payload) {
    if (payload.answer)
      api.updateEntry('answers', payload.answer.id, {
        evidenceText: payload.evidenceText,
        user: rootState.auth.user.id
      })
    else {
      api.createEntry('answers', {
        user: rootState.auth.user.id,
        solution: payload.solutionId,
        subcriterion: payload.subCriterion.id,
        evidenceText: payload.evidenceText
      })
    }
  },

  async get({ commit }) {
    const response = await api.request('post', '/graphql', {
      data: {
        query: `
        query {
          subcriteria {
            id
            name
            label
            booleanQuestion
            textQuestion
            unit
            unitValue
            criterion {
              id
              name
              label
            }
            solutions {
              id
            }
            answers {
              id
              createdAt
              updatedAt
              booleanAnswer
              textAnswer
              evidenceText
              unit
              unitValue
              user {
                id
                username
                lastName
                firstName
              }
              solution {
                id
              }
            }
            cluster {
              id
              name
              negative
            }
            scenarios
          }
        }
        `
      }
    })
    commit('set', response.data.subcriteria)
  },

  async getBySolution({ commit }, solutionId) {
    const response = await api.request('post', '/graphql', {
      data: {
        query: `
        query {
          answers(where: { solution: "${solutionId}" }) {
            id
            solution {
              id
            }
            subcriterion {
              id
              name
              label
              cluster {
                id
                name
                negative
              }
              booleanQuestion
              textQuestion
              criterion {
                id
                name
                label
              }
            }
            booleanAnswer
            textAnswer
            unit
            unitValue
            updated_at: updatedAt
            user {
              id
              username
              firstName
              lastName
            }
            scenarios
          }
        }
        `
      }
    })
    commit('setAnswersForCurrentSolution', response.data.answers)
    return response.data.answers
  },

  /**
   * Blend two colors together.
   * @param {string} color1 - The first color, in hexadecimal format.
   * @param {string} color2 - The second color, in hexadecimal format.
   * @return {string} The blended color.
   */
  async getBySubcriterionAndSolution(
    { commit },
    { subcriterionId, solutionId }
  ) {
    const response = await api.request('post', '/graphql', {
      data: {
        query: `
        query {
          answers(where: { solution: "${solutionId}", subcriterion: "${subcriterionId}" }) {
            id
            solution {
              id
            }
            subcriterion {
              id
              booleanQuestion
              textQuestion
              criterion {
                id
                name
                label
              }
            }
            booleanAnswer
            textAnswer
            unit
            unitValue
            updated_at: updatedAt
            user {
              id
              username
              firstName
              lastName
            }
            scenarios
          }
        }
        `
      }
    })
    commit('setAnswersForCurrentSolution', response.data.answers)
    return response.data.answers
  }
}

export const mutations = {
  set(state, answers) {
    state.list = answers
  },
  add(state, answer) {
    state.list.push(answer)
  },
  emptyList(state) {
    state.list = []
  },
  setAnswersForCurrentSolution(state, answers) {
    state.listOfCurrentSolution = answers
  }
}

export const getters = {
  list: (state) => {
    return state.list
  },
  count: (state) => state.listOfCurrentSolution.length,
  currentSolution: (state) => state.listOfCurrentSolution
}
