import Vue from 'vue'
import HighchartsVue from 'highcharts-vue'

import Highcharts from 'highcharts'
import sankey from 'highcharts/modules/sankey'
import dependencyWheel from 'highcharts/modules/dependency-wheel'
import gantt from 'highcharts/modules/gantt'

if (typeof Highcharts === 'object') {
  sankey(Highcharts)
  dependencyWheel(Highcharts)
  gantt(Highcharts)
}

Highcharts.setOptions({
  chart: {
    style: {
      fontFamily: 'Camphor'
    }
  },
  lang: {
    decimalPoint: ',',
    thousandsSep: '.',
    loading: 'Daten werden geladen...',
    months: [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember'
    ],
    weekdays: [
      'Sonntag',
      'Montag',
      'Dienstag',
      'Mittwoch',
      'Donnerstag',
      'Freitag',
      'Samstag'
    ],
    shortMonths: [
      'Jan',
      'Feb',
      'Mär',
      'Apr',
      'Mai',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Okt',
      'Nov',
      'Dez'
    ],
    exportButtonTitle: 'Exportieren',
    printButtonTitle: 'Drucken',
    rangeSelectorFrom: 'Von',
    rangeSelectorTo: 'Bis',
    rangeSelectorZoom: 'Zeitraum',
    downloadPNG: 'Download als PNG-Bild',
    downloadJPEG: 'Download als JPEG-Bild',
    downloadPDF: 'Download als PDF-Dokument',
    downloadSVG: 'Download als SVG-Bild',
    resetZoom: 'Zoom zurücksetzen',
    resetZoomTitle: 'Zoom zurücksetzen'
  }
})

Vue.use(HighchartsVue)
