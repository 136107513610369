//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      communicationToolSheet: false,
      communicationToolMessage: 'Sehr geehrte/r Dame/Herr, ...',
      communicationTool: null,
      recipientUser: null
    }
  },
  computed: {
    users() {
      return this.$store.getters['users/list']
    }
  },
  mounted() {
    this.$root.$on('openCommuniationToolPopup', (event) => {
      this.communicationToolSheet = true
      this.communicationTool = event
    })
  },
  methods: {
    async sendMessage() {
      if (!this.recipientUser.id) return
      const newRoom = await this.$store.dispatch('rooms/create', {
        subject: this.communicationTool.subject,
        type: this.communicationTool.type,
        subcriterion: this.communicationTool.subCriterion.id,
        project: this.$route.params.id,
        createdBy: this.$auth.user.id,
        partnerId: this.recipientUser.id,
        solution: this.$route.params.solution
      })
      this.communicationToolSheet = false

      this.$store.dispatch('messages/send', {
        body: this.communicationToolMessage,
        room: newRoom.id,
        from: this.$auth.user.id,
        to: this.recipientUser.id
      })
    },
    roomType(type) {
      if (!type) return ''
      const dict = {
        question: 'Information erfragen',
        delegate: 'Frage delegieren',
        conflict: 'Konflikt aushandeln',
        discussSolution: 'Lösung aushandeln',
        answerValidation: 'Antwortvalidierung',
        other: 'Sonstiges'
      }

      return dict[type]
    }
  }
}
