import api from '~/plugins/api'

export const state = () => ({
  list: [],
  current: {}
})

/* @params payload Object
payload.criterionName
payload.solutionId
*/
export const actions = {
  async get({ commit }, payload) {
    const response = await api.request('post', '/graphql', {
      data: {
        query: `
          query {
            criteria (where: { name: "${payload.criterionName}" }) {
              id
              label
              name
              definitionContent
              definitionSource
            }
          }
        `
      }
    })

    // Workaround: Separating into two queries to get solutions IDs
    const responseSubcriteria = await api.request('post', '/graphql', {
      data: {
        query: `
          query {
            subcriteria(where: { criterion: "${response.data.criteria[0].id}" }) {
              id
              name
              label
              booleanQuestion
              textQuestion
              definitionContent
              definitionSource
              unit
              unitValue
              topic {
                id
                label
              }
              solutions {
                id
              }
            }
          }
        `
      }
    })

    const responseAnswers = await api.request('post', '/graphql', {
      data: {
        query: `
          query {
            answers(where: { solution: "${payload.solutionId}"}) {
              id
              booleanAnswer
              textAnswer
              unit
              unitValue
              evidenceText
              updated_at: updatedAt
              solution {
                id
              }
              subcriterion {
                id
              }
              user {
                id
                username
              }
              scenarios
              involvedOrganisations
              staff
              functionalities
              technicalDescription
            }
          }
        `
      }
    })

    const currentCriterion = response.data.criteria[0]
    currentCriterion.subcriteria = responseSubcriteria.data.subcriteria

    currentCriterion.subcriteria.forEach((subcriterion) => {
      subcriterion.answers = []
      if (responseAnswers.data.answers) {
        responseAnswers.data.answers.forEach((answer) => {
          if (answer.subcriterion.id === subcriterion.id)
            subcriterion.answers.push(answer)
        })
      }
    })
    commit('setCurrent', currentCriterion)
  }
}

export const mutations = {
  setCurrent(state, criterion) {
    state.current = criterion
  },
  emptyList(state) {
    state.list = []
  }
  /* update (state, payload) {
    state.current.subcriteria.forEach(subcriterion => {
      if (Number(subcriterion.id) === Number(payload.answer.subcriterion.id)) {
        let answerIndex = subcriterion.answers.findIndex(answer => Number(answer.solution.id) === Number(payload.answer.solution.id))
        if (answerIndex > -1) {
          subcriterion.answers[answerIndex] = payload.answer
          state.current = JSON.parse(JSON.stringify(state.current))
        } else {
          subcriterion.answers.push(payload.answer)
          state.current = JSON.parse(JSON.stringify(state.current))
        }
      }
    })
  } */
}

export const getters = {
  list: (state) => {
    return state.list
  },
  current: (state) => state.current
}
