import api from '~/plugins/api'

export const state = () => ({
  list: []
})

export const actions = {
  send({ commit }, payload) {
    api.createEntry('messages', payload)
  }
}

export const mutations = {
  add(state, message) {
    state.list.push(message)
  },
  emptyList(state) {
    state.list = []
  }
}

export const getters = {
  list: (state) => {
    return state.list
  }
}
