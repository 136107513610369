import Strapi from 'strapi-sdk-javascript/build/main'
// const apiUrl = process.env.API_URL || 'https://4d-tool.ztg.tu-berlin.de/api'
const apiUrl = process.env.baseUrl + process.env.apiUrl

export default new Strapi(apiUrl)
/* export default new Strapi(apiUrl, {
  localStorage: {
    key: 'auth._token.local'
  },
  cookie: {
    key: 'auth._token.local'
  }
}) */
