import api from '~/plugins/api'

export const state = () => ({
  list: []
})

export const actions = {
  async getBySolution({ commit }, solutionId) {
    const response = await api.request('post', '/graphql', {
      data: {
        query: `
          query {
            solution(id: "${solutionId}") {
              id
              subcriteria {
                id
                criterion {
                  id
                }
              }
            }
          }
        `
      }
    })

    return response
  },
  emptyList(state) {
    state.list = []
  }
}

export const mutations = {
  add(state, subcriterion) {
    state.list.push(subcriterion)
  },
  emptyList(state) {
    state.list = []
  }
}

export const getters = {
  list: (state) => {
    return state.list
  },
  count: (state) => state.list.length
}
