import api from '~/plugins/api'

export const state = () => ({
  list: [],
  currentScenario: null
})

export const actions = {
  async get({ commit }, projectId) {
    const scenariosResponse = await api.request('post', '/graphql', {
      data: {
        query: `
          query {
            scenarios (where: { project: "${projectId}" }, sort: "id") {
              id
              label
              subjectOfProject
              applicableForAllSolutions
            }
          }
          `
      }
    })
    commit('set', scenariosResponse.data.scenarios)
  },
  async getById({ commit }, scenarioId) {
    const scenarioIdNumber = Number(scenarioId)
    const scenariosResponse = await api.request('post', '/graphql', {
      data: {
        query: `
          query {
            scenario(id: "${scenarioIdNumber}") {
              id
              label
              applicationContext
              previousProcess
              expectedProcess
              deviationFromExpectedProcess
              actors
              subjectOfProject
              needForAction
              locations
              dateRange
              timeRange
              weekdays
              daytimes
              elements
              applicableForAllSolutions
            }
          }
          `
      }
    })
    commit('setCurrent', scenariosResponse.data.scenario)
  },
  async addNew({ commit, rootState }) {
    const newScenario = await api.createEntry('scenarios', {
      project: rootState.projects.currentProject.id
    })
    commit('addNew', newScenario)
  },
  async addDuplicate({ commit }, originalId) {
    const scenarioCopy = await api.getEntry('scenarios', originalId)
    delete scenarioCopy.id
    scenarioCopy.label = scenarioCopy.label + ' (Duplikat)'
    const newScenario = await api.createEntry('scenarios', scenarioCopy)
    commit('addNew', newScenario)
  },
  async updateScenario(
    { commit, state, rootState, dispatch },
    { field, value }
  ) {
    await api.updateEntry('scenarios', Number(state.currentScenario.id), {
      [field]: value
    })
    console.info('saving scenario...', { field, value })
    dispatch('autoSave', null, { root: true })
    // dispatch('get', rootState.projects.currentProject.id)
    commit('updateScenario', { field, value })
  },
  async deleteScenario({ dispatch, rootState }, scenarioId) {
    await api.deleteEntry('scenarios', Number(scenarioId))
    console.info('deleting scenario...', scenarioId)
    dispatch('autoSave', null, { root: true })
    dispatch('get', rootState.projects.currentProject.id)
  }
}

export const mutations = {
  set(state, scenarios) {
    state.list = scenarios
  },
  addNew(state, scenario) {
    state.list.push(scenario)
  },
  setCurrent(state, scenario) {
    state.currentScenario = scenario
  },
  emptyList(state) {
    state.list = []
  },
  updateScenario(state, { field, value }) {
    Object.assign(state.currentScenario, {
      [field]: value
    })
  }
}

export const getters = {
  list: (state) => {
    return state.list
  },
  currentScenario: (state) => {
    return state.currentScenario
  }
}
