export const state = () => ({
  list: []
})

export const mutations = {
  add(state, item) {
    state.list = item
  },
  addAnswer(state, subcriterion) {
    const dim = state.list.find(
      (dimension) => dimension.id === subcriterion.dimension.id
    )
    dim.numberOfAnswers++
  },
  emptyList(state) {
    state.list = []
  }
}

export const getters = {
  list: (state) => {
    return state.list
  }
}
