//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      drawer: true,
      items: [
        {
          icon: 'border_color',
          title: 'Fragen-Editor',
          to: { name: 'admin-question-editor' }
        },
        {
          icon: 'border_color',
          title: 'Wiki-Import',
          to: { name: 'admin-wiki-import' }
        }
      ]
    }
  }
}
