//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import communicationTool from '~/components/communicationTool'

export default {
  components: {
    communicationTool
  },
  data() {
    return {
      drawer: true,
      progressValue: 100,
      loader: null,
      loading: false,
      loadingModal: false,
      items: [
        {
          icon: 'calendar_view_day',
          title: 'Konformität und Gewährleistungsanalyse',
          group: '/answers',
          items: [
            {
              title: 'Überblick',
              to: 'projects-id-analysis-solution-scenario-answers-summary'
            },
            {
              title: 'Antwortvalidierung',
              to:
                'projects-id-analysis-solution-scenario-answers-answer-validation'
            }
          ]
        },
        {
          icon: 'call_merge',
          title: 'Konfliktanalyse',
          group: '/conflicts',
          items: [
            {
              title: 'Überblick',
              to: 'projects-id-analysis-solution-scenario-conflicts-summary'
            },
            {
              title: 'Konfliktvalidierung',
              to:
                'projects-id-analysis-solution-scenario-conflicts-conflict-validation'
            },
            {
              title: 'Konfliktbearbeitung',
              to:
                'projects-id-analysis-solution-scenario-conflicts-conflict-processing'
            },
            {
              title: 'Kosten-Konflikt-Analyse',
              to:
                'projects-id-analysis-solution-scenario-conflicts-cost-conflict-analysis'
            }
          ]
        },
        {
          icon: 'view_headline',
          title: 'Berichte',
          group: '/assessment-quality',
          items: [
            {
              title: 'Überblick',
              to:
                'projects-id-analysis-solution-scenario-assessment-quality-summary'
            }
          ]
        },
        {
          icon: 'euro_symbol',
          title: 'Kosten-Nutzen-Konflikte',
          group: '/assessment-quality',
          items: [
            {
              title: 'Überblick',
              to:
                'projects-id-analysis-solution-scenario-assessment-quality-summary'
            }
          ]
        },
        {
          icon: 'receipt',
          title: 'Bewertungsgüte',
          group: '/assessment-quality',
          items: [
            {
              title: 'Überblick',
              to:
                'projects-id-analysis-solution-scenario-assessment-quality-summary'
            }
          ]
        },
        {
          icon: 'compare_arrows',
          title: 'Vergleiche und Optimierung',
          group: '/compare-optimize',
          items: [
            {
              title: 'Überblick',
              to:
                'projects-id-analysis-solution-scenario-compare-optimize-summary'
            },
            {
              title: 'Innerhalb eines Szenarios',
              to:
                'projects-id-analysis-solution-scenario-compare-optimize-compare-proposals'
            },
            {
              title: 'Szenarien im Vergleich',
              to:
                'projects-id-analysis-solution-scenario-compare-optimize-compare-scenarios'
            }
          ]
        }
      ]
    }
  },
  computed: {
    project() {
      return this.$store.getters['projects/currentProject']
    },
    conflictsLoaded() {
      return this.$store.getters['conflicts/loaded']
    },
    solutions() {
      return this.$store.getters['solutions/list']
    },
    currentSolution() {
      if (this.solutions.length)
        return this.solutions.filter(
          (solution) => solution.id === this.$route.params.solution
        )[0]
      return {}
    },
    scenarios() {
      return this.$store.getters['scenarios/list']
    },
    currentScenario() {
      if (this.scenarios.length)
        return this.scenarios.filter(
          (scenario) => scenario.id === this.$route.params.scenario
        )[0]
      return {}
    }
  },
  watch: {
    loader() {
      const l = this.loader
      this[l] = !this[l]

      setTimeout(() => (this[l] = false), 3000)

      this.loader = null
    }
  },
  mounted() {
    this.$root.$confirm = this.$refs.confirm.open
    // if (this.$auth.user.role.id !== 1) this.$router.push('/dashboard')
  },
  async beforeMount() {
    if (this.$route.params.solution) {
      try {
        // await this.$store.dispatch('conflicts/get', this.$route.params.solution)
        // await this.$store.dispatch('answers/get')
        this.$store.dispatch('projects/loadProjectById', this.$route.params.id)
        // await this.$store.dispatch('answers/getBySolution', this.$route.params.solution)
        this.$store.dispatch('solutions/get', this.$route.params.id)
        await this.$store.dispatch('scenarios/get', this.$route.params.id)
        // await this.$store.dispatch('institutions/get', this.$route.params.id) // for communication tool
        await this.$store.dispatch('users/get') // for communication tool
      } catch (err) {
        // alert('Fehler beim Laden. Sie werden zum Dashboard weitergeleitet.')
        console.error(err)
        // this.$router.push({ name: 'dashboard' })
      }
    }
    setTimeout(() => (this.loadingModal = false), 1000)
  }
}
