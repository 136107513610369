import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2e309646 = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _a4d4c37c = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _ed9a3172 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _3b195b84 = () => interopDefault(import('../pages/help/index.vue' /* webpackChunkName: "pages/help/index" */))
const _5f3e18b9 = () => interopDefault(import('../pages/imprint.vue' /* webpackChunkName: "pages/imprint" */))
const _7155a2ae = () => interopDefault(import('../pages/library/index.vue' /* webpackChunkName: "pages/library/index" */))
const _b10e9c48 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _7af97062 = () => interopDefault(import('../pages/messages/index.vue' /* webpackChunkName: "pages/messages/index" */))
const _0ee328b8 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _df206b8c = () => interopDefault(import('../pages/project-management/index.vue' /* webpackChunkName: "pages/project-management/index" */))
const _8aa28506 = () => interopDefault(import('../pages/projects/index.vue' /* webpackChunkName: "pages/projects/index" */))
const _4d63f9b2 = () => interopDefault(import('../pages/admin/question-editor/index.vue' /* webpackChunkName: "pages/admin/question-editor/index" */))
const _54121656 = () => interopDefault(import('../pages/admin/wiki-import/index.vue' /* webpackChunkName: "pages/admin/wiki-import/index" */))
const _1e07d4d7 = () => interopDefault(import('../pages/library/guidelines.vue' /* webpackChunkName: "pages/library/guidelines" */))
const _1e6e9115 = () => interopDefault(import('../pages/library/legal.vue' /* webpackChunkName: "pages/library/legal" */))
const _49eb81a5 = () => interopDefault(import('../pages/library/literature.vue' /* webpackChunkName: "pages/library/literature" */))
const _f4b59692 = () => interopDefault(import('../pages/library/others.vue' /* webpackChunkName: "pages/library/others" */))
const _72111625 = () => interopDefault(import('../pages/library/project-relevant.vue' /* webpackChunkName: "pages/library/project-relevant" */))
const _032c92b8 = () => interopDefault(import('../pages/library/specifications.vue' /* webpackChunkName: "pages/library/specifications" */))
const _61ad6b0f = () => interopDefault(import('../pages/library/surveys-studies.vue' /* webpackChunkName: "pages/library/surveys-studies" */))
const _00095c20 = () => interopDefault(import('../pages/login/logout.vue' /* webpackChunkName: "pages/login/logout" */))
const _1a208f82 = () => interopDefault(import('../pages/login/reset-password.vue' /* webpackChunkName: "pages/login/reset-password" */))
const _748dd00b = () => interopDefault(import('../pages/projects/new.vue' /* webpackChunkName: "pages/projects/new" */))
const _2494b692 = () => interopDefault(import('../pages/messages/_room.vue' /* webpackChunkName: "pages/messages/_room" */))
const _ce7a5c30 = () => interopDefault(import('../pages/projects/_id/index.vue' /* webpackChunkName: "pages/projects/_id/index" */))
const _0ad11539 = () => interopDefault(import('../pages/projects/_id/analysis/index.vue' /* webpackChunkName: "pages/projects/_id/analysis/index" */))
const _6446691f = () => interopDefault(import('../pages/projects/_id/assessment/index.vue' /* webpackChunkName: "pages/projects/_id/assessment/index" */))
const _f88728f6 = () => interopDefault(import('../pages/projects/_id/configuration/budgetmanager.vue' /* webpackChunkName: "pages/projects/_id/configuration/budgetmanager" */))
const _0301a41b = () => interopDefault(import('../pages/projects/_id/configuration/criteria-editor/index.vue' /* webpackChunkName: "pages/projects/_id/configuration/criteria-editor/index" */))
const _7ecf0b65 = () => interopDefault(import('../pages/projects/_id/configuration/general.vue' /* webpackChunkName: "pages/projects/_id/configuration/general" */))
const _6c1975c8 = () => interopDefault(import('../pages/projects/_id/configuration/notes-moderation/index.vue' /* webpackChunkName: "pages/projects/_id/configuration/notes-moderation/index" */))
const _d3068d72 = () => interopDefault(import('../pages/projects/_id/configuration/report.vue' /* webpackChunkName: "pages/projects/_id/configuration/report" */))
const _99bc4452 = () => interopDefault(import('../pages/projects/_id/configuration/risk-analysis.vue' /* webpackChunkName: "pages/projects/_id/configuration/risk-analysis" */))
const _5ddc26a0 = () => interopDefault(import('../pages/projects/_id/configuration/scenarios.vue' /* webpackChunkName: "pages/projects/_id/configuration/scenarios" */))
const _4d4d95ba = () => interopDefault(import('../pages/projects/_id/configuration/scenarios/index.vue' /* webpackChunkName: "pages/projects/_id/configuration/scenarios/index" */))
const _9c4d5380 = () => interopDefault(import('../pages/projects/_id/configuration/scenarios/_scenario.vue' /* webpackChunkName: "pages/projects/_id/configuration/scenarios/_scenario" */))
const _8ca180cc = () => interopDefault(import('../pages/projects/_id/configuration/solutions/index.vue' /* webpackChunkName: "pages/projects/_id/configuration/solutions/index" */))
const _1aa07592 = () => interopDefault(import('../pages/projects/_id/configuration/topics.vue' /* webpackChunkName: "pages/projects/_id/configuration/topics" */))
const _ba829476 = () => interopDefault(import('../pages/projects/_id/configuration/users.vue' /* webpackChunkName: "pages/projects/_id/configuration/users" */))
const _1650c7da = () => interopDefault(import('../pages/projects/_id/configuration/criteria-editor/criteria-pool.vue' /* webpackChunkName: "pages/projects/_id/configuration/criteria-editor/criteria-pool" */))
const _f3cd09b0 = () => interopDefault(import('../pages/projects/_id/configuration/notes-moderation/idea-pool.vue' /* webpackChunkName: "pages/projects/_id/configuration/notes-moderation/idea-pool" */))
const _44901923 = () => interopDefault(import('../pages/projects/_id/configuration/solutions/_solution/index.vue' /* webpackChunkName: "pages/projects/_id/configuration/solutions/_solution/index" */))
const _639ff966 = () => interopDefault(import('../pages/projects/_id/configuration/solutions/_solution/index/index.vue' /* webpackChunkName: "pages/projects/_id/configuration/solutions/_solution/index/index" */))
const _b18f3e78 = () => interopDefault(import('../pages/projects/_id/configuration/solutions/_solution/index/action-relevant-functionalities.vue' /* webpackChunkName: "pages/projects/_id/configuration/solutions/_solution/index/action-relevant-functionalities" */))
const _4ddf05c6 = () => interopDefault(import('../pages/projects/_id/configuration/solutions/_solution/index/application-context.vue' /* webpackChunkName: "pages/projects/_id/configuration/solutions/_solution/index/application-context" */))
const _8553803e = () => interopDefault(import('../pages/projects/_id/configuration/solutions/_solution/index/budget.vue' /* webpackChunkName: "pages/projects/_id/configuration/solutions/_solution/index/budget" */))
const _2f324152 = () => interopDefault(import('../pages/projects/_id/configuration/solutions/_solution/index/central-functions.vue' /* webpackChunkName: "pages/projects/_id/configuration/solutions/_solution/index/central-functions" */))
const _1eca0f7c = () => interopDefault(import('../pages/projects/_id/configuration/solutions/_solution/index/general.vue' /* webpackChunkName: "pages/projects/_id/configuration/solutions/_solution/index/general" */))
const _d4c35314 = () => interopDefault(import('../pages/projects/_id/configuration/solutions/_solution/index/interfaces.vue' /* webpackChunkName: "pages/projects/_id/configuration/solutions/_solution/index/interfaces" */))
const _62340efa = () => interopDefault(import('../pages/projects/_id/configuration/solutions/_solution/index/involved-participants.vue' /* webpackChunkName: "pages/projects/_id/configuration/solutions/_solution/index/involved-participants" */))
const _8cfa5884 = () => interopDefault(import('../pages/projects/_id/configuration/solutions/_solution/index/legal-basis.vue' /* webpackChunkName: "pages/projects/_id/configuration/solutions/_solution/index/legal-basis" */))
const _6a3333e8 = () => interopDefault(import('../pages/projects/_id/configuration/solutions/_solution/index/process-flow.vue' /* webpackChunkName: "pages/projects/_id/configuration/solutions/_solution/index/process-flow" */))
const _31426041 = () => interopDefault(import('../pages/projects/_id/configuration/solutions/_solution/index/responsible-parties.vue' /* webpackChunkName: "pages/projects/_id/configuration/solutions/_solution/index/responsible-parties" */))
const _18e41d20 = () => interopDefault(import('../pages/projects/_id/configuration/solutions/_solution/index/technical-description.vue' /* webpackChunkName: "pages/projects/_id/configuration/solutions/_solution/index/technical-description" */))
const _946f3b80 = () => interopDefault(import('../pages/projects/_id/configuration/solutions/_solution/index/topics.vue' /* webpackChunkName: "pages/projects/_id/configuration/solutions/_solution/index/topics" */))
const _6ea45f6c = () => interopDefault(import('../pages/projects/_id/configuration/solutions/_solution/index/use-purpose.vue' /* webpackChunkName: "pages/projects/_id/configuration/solutions/_solution/index/use-purpose" */))
const _2336b402 = () => interopDefault(import('../pages/projects/_id/analysis/_solution/index.vue' /* webpackChunkName: "pages/projects/_id/analysis/_solution/index" */))
const _4c4e3f30 = () => interopDefault(import('../pages/projects/_id/assessment/_solution/index.vue' /* webpackChunkName: "pages/projects/_id/assessment/_solution/index" */))
const _6d2be0e2 = () => interopDefault(import('../pages/projects/_id/analysis/_solution/_scenario/index.vue' /* webpackChunkName: "pages/projects/_id/analysis/_solution/_scenario/index" */))
const _7a7ab602 = () => interopDefault(import('../pages/projects/_id/assessment/_solution/_criterion/index.vue' /* webpackChunkName: "pages/projects/_id/assessment/_solution/_criterion/index" */))
const _64762dc7 = () => interopDefault(import('../pages/projects/_id/analysis/_solution/_scenario/topics/index.vue' /* webpackChunkName: "pages/projects/_id/analysis/_solution/_scenario/topics/index" */))
const _35ebae44 = () => interopDefault(import('../pages/projects/_id/analysis/_solution/_scenario/answers/answer-validation.vue' /* webpackChunkName: "pages/projects/_id/analysis/_solution/_scenario/answers/answer-validation" */))
const _52bb2d1c = () => interopDefault(import('../pages/projects/_id/analysis/_solution/_scenario/answers/summary.vue' /* webpackChunkName: "pages/projects/_id/analysis/_solution/_scenario/answers/summary" */))
const _61331b2b = () => interopDefault(import('../pages/projects/_id/analysis/_solution/_scenario/assessment-quality/summary.vue' /* webpackChunkName: "pages/projects/_id/analysis/_solution/_scenario/assessment-quality/summary" */))
const _504707af = () => interopDefault(import('../pages/projects/_id/analysis/_solution/_scenario/compare-optimize/compare-proposals.vue' /* webpackChunkName: "pages/projects/_id/analysis/_solution/_scenario/compare-optimize/compare-proposals" */))
const _1796b1d1 = () => interopDefault(import('../pages/projects/_id/analysis/_solution/_scenario/compare-optimize/compare-scenarios.vue' /* webpackChunkName: "pages/projects/_id/analysis/_solution/_scenario/compare-optimize/compare-scenarios" */))
const _6c3fbd7c = () => interopDefault(import('../pages/projects/_id/analysis/_solution/_scenario/compare-optimize/summary.vue' /* webpackChunkName: "pages/projects/_id/analysis/_solution/_scenario/compare-optimize/summary" */))
const _bcfa095a = () => interopDefault(import('../pages/projects/_id/analysis/_solution/_scenario/conflicts/conflict-processing/index.vue' /* webpackChunkName: "pages/projects/_id/analysis/_solution/_scenario/conflicts/conflict-processing/index" */))
const _756f7194 = () => interopDefault(import('../pages/projects/_id/analysis/_solution/_scenario/conflicts/conflict-validation.vue' /* webpackChunkName: "pages/projects/_id/analysis/_solution/_scenario/conflicts/conflict-validation" */))
const _710f80e9 = () => interopDefault(import('../pages/projects/_id/analysis/_solution/_scenario/conflicts/conflict-validation-backup.vue' /* webpackChunkName: "pages/projects/_id/analysis/_solution/_scenario/conflicts/conflict-validation-backup" */))
const _51e3c205 = () => interopDefault(import('../pages/projects/_id/analysis/_solution/_scenario/conflicts/cost-conflict-analysis.vue' /* webpackChunkName: "pages/projects/_id/analysis/_solution/_scenario/conflicts/cost-conflict-analysis" */))
const _5de62ee4 = () => interopDefault(import('../pages/projects/_id/analysis/_solution/_scenario/conflicts/interdependence-analysis.vue' /* webpackChunkName: "pages/projects/_id/analysis/_solution/_scenario/conflicts/interdependence-analysis" */))
const _786b4388 = () => interopDefault(import('../pages/projects/_id/analysis/_solution/_scenario/conflicts/summary.vue' /* webpackChunkName: "pages/projects/_id/analysis/_solution/_scenario/conflicts/summary" */))
const _a0d82f9c = () => interopDefault(import('../pages/projects/_id/analysis/_solution/_scenario/topics/acceptance.vue' /* webpackChunkName: "pages/projects/_id/analysis/_solution/_scenario/topics/acceptance" */))
const _72d22e62 = () => interopDefault(import('../pages/projects/_id/analysis/_solution/_scenario/topics/iso25010.vue' /* webpackChunkName: "pages/projects/_id/analysis/_solution/_scenario/topics/iso25010" */))
const _681e7a3d = () => interopDefault(import('../pages/projects/_id/analysis/_solution/_scenario/topics/privacy.vue' /* webpackChunkName: "pages/projects/_id/analysis/_solution/_scenario/topics/privacy" */))
const _3fef1a41 = () => interopDefault(import('../pages/projects/_id/analysis/_solution/_scenario/topics/usability.vue' /* webpackChunkName: "pages/projects/_id/analysis/_solution/_scenario/topics/usability" */))
const _2b024bb2 = () => interopDefault(import('../pages/projects/_id/analysis/_solution/_scenario/conflicts/conflict-processing/_conflict.vue' /* webpackChunkName: "pages/projects/_id/analysis/_solution/_scenario/conflicts/conflict-processing/_conflict" */))
const _abf51b26 = () => interopDefault(import('../pages/projects/_id/assessment/_solution/_criterion/_subcriterion.vue' /* webpackChunkName: "pages/projects/_id/assessment/_solution/_criterion/_subcriterion" */))
const _480b81fc = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _2e309646,
    name: "account"
  }, {
    path: "/admin",
    component: _a4d4c37c,
    name: "admin"
  }, {
    path: "/dashboard",
    component: _ed9a3172,
    name: "dashboard"
  }, {
    path: "/help",
    component: _3b195b84,
    name: "help"
  }, {
    path: "/imprint",
    component: _5f3e18b9,
    name: "imprint"
  }, {
    path: "/library",
    component: _7155a2ae,
    name: "library"
  }, {
    path: "/login",
    component: _b10e9c48,
    name: "login"
  }, {
    path: "/messages",
    component: _7af97062,
    name: "messages"
  }, {
    path: "/privacy",
    component: _0ee328b8,
    name: "privacy"
  }, {
    path: "/project-management",
    component: _df206b8c,
    name: "project-management"
  }, {
    path: "/projects",
    component: _8aa28506,
    name: "projects"
  }, {
    path: "/admin/question-editor",
    component: _4d63f9b2,
    name: "admin-question-editor"
  }, {
    path: "/admin/wiki-import",
    component: _54121656,
    name: "admin-wiki-import"
  }, {
    path: "/library/guidelines",
    component: _1e07d4d7,
    name: "library-guidelines"
  }, {
    path: "/library/legal",
    component: _1e6e9115,
    name: "library-legal"
  }, {
    path: "/library/literature",
    component: _49eb81a5,
    name: "library-literature"
  }, {
    path: "/library/others",
    component: _f4b59692,
    name: "library-others"
  }, {
    path: "/library/project-relevant",
    component: _72111625,
    name: "library-project-relevant"
  }, {
    path: "/library/specifications",
    component: _032c92b8,
    name: "library-specifications"
  }, {
    path: "/library/surveys-studies",
    component: _61ad6b0f,
    name: "library-surveys-studies"
  }, {
    path: "/login/logout",
    component: _00095c20,
    name: "login-logout"
  }, {
    path: "/login/reset-password",
    component: _1a208f82,
    name: "login-reset-password"
  }, {
    path: "/projects/new",
    component: _748dd00b,
    name: "projects-new"
  }, {
    path: "/messages/:room",
    component: _2494b692,
    name: "messages-room"
  }, {
    path: "/projects/:id",
    component: _ce7a5c30,
    name: "projects-id"
  }, {
    path: "/projects/:id/analysis",
    component: _0ad11539,
    name: "projects-id-analysis"
  }, {
    path: "/projects/:id/assessment",
    component: _6446691f,
    name: "projects-id-assessment"
  }, {
    path: "/projects/:id/configuration/budgetmanager",
    component: _f88728f6,
    name: "projects-id-configuration-budgetmanager"
  }, {
    path: "/projects/:id/configuration/criteria-editor",
    component: _0301a41b,
    name: "projects-id-configuration-criteria-editor"
  }, {
    path: "/projects/:id/configuration/general",
    component: _7ecf0b65,
    name: "projects-id-configuration-general"
  }, {
    path: "/projects/:id/configuration/notes-moderation",
    component: _6c1975c8,
    name: "projects-id-configuration-notes-moderation"
  }, {
    path: "/projects/:id/configuration/report",
    component: _d3068d72,
    name: "projects-id-configuration-report"
  }, {
    path: "/projects/:id/configuration/risk-analysis",
    component: _99bc4452,
    name: "projects-id-configuration-risk-analysis"
  }, {
    path: "/projects/:id/configuration/scenarios",
    component: _5ddc26a0,
    children: [{
      path: "",
      component: _4d4d95ba,
      name: "projects-id-configuration-scenarios"
    }, {
      path: ":scenario",
      component: _9c4d5380,
      name: "projects-id-configuration-scenarios-scenario"
    }]
  }, {
    path: "/projects/:id/configuration/solutions",
    component: _8ca180cc,
    name: "projects-id-configuration-solutions"
  }, {
    path: "/projects/:id/configuration/topics",
    component: _1aa07592,
    name: "projects-id-configuration-topics"
  }, {
    path: "/projects/:id/configuration/users",
    component: _ba829476,
    name: "projects-id-configuration-users"
  }, {
    path: "/projects/:id/configuration/criteria-editor/criteria-pool",
    component: _1650c7da,
    name: "projects-id-configuration-criteria-editor-criteria-pool"
  }, {
    path: "/projects/:id/configuration/notes-moderation/idea-pool",
    component: _f3cd09b0,
    name: "projects-id-configuration-notes-moderation-idea-pool"
  }, {
    path: "/projects/:id/configuration/solutions/:solution",
    component: _44901923,
    children: [{
      path: "",
      component: _639ff966,
      name: "projects-id-configuration-solutions-solution-index"
    }, {
      path: "action-relevant-functionalities",
      component: _b18f3e78,
      name: "projects-id-configuration-solutions-solution-index-action-relevant-functionalities"
    }, {
      path: "application-context",
      component: _4ddf05c6,
      name: "projects-id-configuration-solutions-solution-index-application-context"
    }, {
      path: "budget",
      component: _8553803e,
      name: "projects-id-configuration-solutions-solution-index-budget"
    }, {
      path: "central-functions",
      component: _2f324152,
      name: "projects-id-configuration-solutions-solution-index-central-functions"
    }, {
      path: "general",
      component: _1eca0f7c,
      name: "projects-id-configuration-solutions-solution-index-general"
    }, {
      path: "interfaces",
      component: _d4c35314,
      name: "projects-id-configuration-solutions-solution-index-interfaces"
    }, {
      path: "involved-participants",
      component: _62340efa,
      name: "projects-id-configuration-solutions-solution-index-involved-participants"
    }, {
      path: "legal-basis",
      component: _8cfa5884,
      name: "projects-id-configuration-solutions-solution-index-legal-basis"
    }, {
      path: "process-flow",
      component: _6a3333e8,
      name: "projects-id-configuration-solutions-solution-index-process-flow"
    }, {
      path: "responsible-parties",
      component: _31426041,
      name: "projects-id-configuration-solutions-solution-index-responsible-parties"
    }, {
      path: "technical-description",
      component: _18e41d20,
      name: "projects-id-configuration-solutions-solution-index-technical-description"
    }, {
      path: "topics",
      component: _946f3b80,
      name: "projects-id-configuration-solutions-solution-index-topics"
    }, {
      path: "use-purpose",
      component: _6ea45f6c,
      name: "projects-id-configuration-solutions-solution-index-use-purpose"
    }]
  }, {
    path: "/projects/:id/analysis/:solution",
    component: _2336b402,
    name: "projects-id-analysis-solution"
  }, {
    path: "/projects/:id/assessment/:solution",
    component: _4c4e3f30,
    name: "projects-id-assessment-solution"
  }, {
    path: "/projects/:id/analysis/:solution/:scenario",
    component: _6d2be0e2,
    name: "projects-id-analysis-solution-scenario"
  }, {
    path: "/projects/:id/assessment/:solution/:criterion",
    component: _7a7ab602,
    name: "projects-id-assessment-solution-criterion"
  }, {
    path: "/projects/:id/analysis/:solution/:scenario/topics",
    component: _64762dc7,
    name: "projects-id-analysis-solution-scenario-topics"
  }, {
    path: "/projects/:id/analysis/:solution/:scenario/answers/answer-validation",
    component: _35ebae44,
    name: "projects-id-analysis-solution-scenario-answers-answer-validation"
  }, {
    path: "/projects/:id/analysis/:solution/:scenario/answers/summary",
    component: _52bb2d1c,
    name: "projects-id-analysis-solution-scenario-answers-summary"
  }, {
    path: "/projects/:id/analysis/:solution/:scenario/assessment-quality/summary",
    component: _61331b2b,
    name: "projects-id-analysis-solution-scenario-assessment-quality-summary"
  }, {
    path: "/projects/:id/analysis/:solution/:scenario/compare-optimize/compare-proposals",
    component: _504707af,
    name: "projects-id-analysis-solution-scenario-compare-optimize-compare-proposals"
  }, {
    path: "/projects/:id/analysis/:solution/:scenario/compare-optimize/compare-scenarios",
    component: _1796b1d1,
    name: "projects-id-analysis-solution-scenario-compare-optimize-compare-scenarios"
  }, {
    path: "/projects/:id/analysis/:solution/:scenario/compare-optimize/summary",
    component: _6c3fbd7c,
    name: "projects-id-analysis-solution-scenario-compare-optimize-summary"
  }, {
    path: "/projects/:id/analysis/:solution/:scenario/conflicts/conflict-processing",
    component: _bcfa095a,
    name: "projects-id-analysis-solution-scenario-conflicts-conflict-processing"
  }, {
    path: "/projects/:id/analysis/:solution/:scenario/conflicts/conflict-validation",
    component: _756f7194,
    name: "projects-id-analysis-solution-scenario-conflicts-conflict-validation"
  }, {
    path: "/projects/:id/analysis/:solution/:scenario/conflicts/conflict-validation-backup",
    component: _710f80e9,
    name: "projects-id-analysis-solution-scenario-conflicts-conflict-validation-backup"
  }, {
    path: "/projects/:id/analysis/:solution/:scenario/conflicts/cost-conflict-analysis",
    component: _51e3c205,
    name: "projects-id-analysis-solution-scenario-conflicts-cost-conflict-analysis"
  }, {
    path: "/projects/:id/analysis/:solution/:scenario/conflicts/interdependence-analysis",
    component: _5de62ee4,
    name: "projects-id-analysis-solution-scenario-conflicts-interdependence-analysis"
  }, {
    path: "/projects/:id/analysis/:solution/:scenario/conflicts/summary",
    component: _786b4388,
    name: "projects-id-analysis-solution-scenario-conflicts-summary"
  }, {
    path: "/projects/:id/analysis/:solution/:scenario/topics/acceptance",
    component: _a0d82f9c,
    name: "projects-id-analysis-solution-scenario-topics-acceptance"
  }, {
    path: "/projects/:id/analysis/:solution/:scenario/topics/iso25010",
    component: _72d22e62,
    name: "projects-id-analysis-solution-scenario-topics-iso25010"
  }, {
    path: "/projects/:id/analysis/:solution/:scenario/topics/privacy",
    component: _681e7a3d,
    name: "projects-id-analysis-solution-scenario-topics-privacy"
  }, {
    path: "/projects/:id/analysis/:solution/:scenario/topics/usability",
    component: _3fef1a41,
    name: "projects-id-analysis-solution-scenario-topics-usability"
  }, {
    path: "/projects/:id/analysis/:solution/:scenario/conflicts/conflict-processing/:conflict?",
    component: _2b024bb2,
    name: "projects-id-analysis-solution-scenario-conflicts-conflict-processing-conflict"
  }, {
    path: "/projects/:id/assessment/:solution/:criterion/:subcriterion",
    component: _abf51b26,
    name: "projects-id-assessment-solution-criterion-subcriterion"
  }, {
    path: "/",
    component: _480b81fc,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
