import api from '~/plugins/api'

export const state = () => ({
  loaded: false,
  conflictList: [],
  conflictListId: null,
  validationList: []
})

export const actions = {
  async create({ commit }, payload) {
    const newconflict = await api.createEntry('conflicts', {
      validationList: { values: [] },
      rejectedList: { values: [] },
      ...payload
    })
    return newconflict
  },
  async get({ commit }, solutionId) {
    try {
      const response = await api.request('post', '/graphql', {
        data: {
          query: `
            query {
              conflicts(where: { solution: "${solutionId}" }) {
                id
                conflictList
                solution {
                  id
                }
                validationList
              }
            }
            `
        }
      })
      if (response.data.conflicts.length)
        commit('setList', response.data.conflicts[0])
      else {
        commit('setLoaded', false)
        commit('emptyList')
      }
    } catch (err) {
      console.log('Fehler beim Laden der Konflikte', err)
    }
  }
}

export const mutations = {
  setStatus(state, payload) {
    const validation = payload.conflict.validation

    const acknowledgedArray = validation.acknowledged
    const acceptedArray = validation.accepted
    const rejectedArray = validation.rejected

    validation.acknowledged = acknowledgedArray.filter(
      (user) => user !== payload.user
    )
    validation.accepted = acceptedArray.filter((user) => user !== payload.user)
    validation.rejected = rejectedArray.filter((user) => user !== payload.user)

    validation[payload.status].push(payload.user)

    api.updateEntry('conflicts', state.conflictListId, {
      validationList: {
        validations: state.validationList
      }
    })
  },
  setTolerance(state, payload) {
    payload.conflict.validation.tolerable = payload.tolerance

    api.updateEntry('conflicts', state.conflictListId, {
      validationList: {
        validations: state.validationList
      }
    })
  },
  setList(state, payload) {
    state.conflictList = payload.conflictList.conflicts
    state.validationList = payload.validationList.validations
    state.conflictListId = payload.id
    state.loaded = true
  },
  emptyList(state) {
    state.conflictList = []
    state.validationList = []
  },
  updateValidations(state, validationList) {
    state.validationList = validationList
  },
  setLoaded(state, boolean) {
    state.loaded = boolean
  }
}

export const getters = {
  list: (state) => {
    return [state.conflictList, state.validationList].reduce((a, b) =>
      a.map((c, i) => Object.assign({}, c, b[i]))
    )
  },
  loaded: (state) => state.loaded,
  count: (state) => state.conflictList.length,
  remaining: (state, getters) => {
    const remaining = []
    getters.list.forEach((conflict) => {
      if (conflict.validation.accepted.length) remaining.push(conflict)
    })
    return remaining
  }
}
