import api from '~/plugins/api'

export const state = () => ({
  list: [],
  institutionIds: []
})

export const actions = {
  /**
   * Retrieves all institutions of project
   * @param {Object} param0 commit
   * @param {String} projectId ID of project
   */
  async get({ commit }, projectId) {
    const usersResponse = await api.request('post', '/graphql', {
      data: {
        query: `query {
          institutions(where: { project: "${projectId}" }) {
              id
              name
              label
              user {
                id
                username
                firstName
                lastName
                institution {
                  id
                  name
                }
                role {
                  id
                }
                json
              }
            }
          }
          `
      }
    })
    commit('set', usersResponse.data.institutions)
  },
  async addNew({ commit, rootState }, institution) {
    const newInstitution = await api.createEntry('institutions', {
      name: institution.newInstitutionShortName,
      label: institution.newInstitutionFullName,
      project: rootState.projects.currentProject.id
    })
    commit('addInstitution', newInstitution)
  },
  updateInstitution({ commit, dispatch }, { institutionId, field, value }) {
    dispatch('autoSave', null, { root: true })
    commit('updateInstitution', { institutionId, field, value })
    return api.updateEntry('institutions', Number(institutionId), {
      [field]: value
    })
  }
}

export const mutations = {
  set(state, institutions) {
    state.list = institutions

    // Set institution IDs as array
    const array = []
    institutions.forEach((institution) => array.push(institution.id))
    state.institutionIds = array
  },
  addInstitution(state, institution) {
    state.list.push(institution)
    state.institutionIds.push(institution.id)
  },
  updateInstitution(state, { institutionId, field, value }) {
    const index = state.list.findIndex(
      (institution) => institution.id === institutionId
    )
    state.list[index][field] = value
  },
  emptyList(state) {
    state.list = []
  }
}

export const getters = {
  list: (state) => {
    return state.list
  },

  ids: (state) => state.institutionIds
}
