import api from '~/plugins/api'

export const state = () => ({
  list: [],
  projectLeaderUser: null
})

export const actions = {
  async get({ commit, rootGetters }) {
    const project = rootGetters['projects/currentProject']
    if (project && project.participants) {
      const query = `{ ${project.participants.users
        .map(
          (user) => `user${user}: user(id: ${user}) {
        id
        firstName
        lastName
        institution {
          id
          name
        }
        role {
          id
          name
        }
        group
        json
        email
      }\n`
        )
        .join('')} }`
      const usersResponse = await api.request('post', '/graphql', {
        data: {
          query: `query ${query}`
        }
      })

      const users = Object.values(usersResponse.data)
      commit('set', users)
    }
  },
  async addNew({ commit, rootState }, user) {
    const newUser = await api.createEntry('users', {
      username: user.newUserName,
      password: user.newUserPassword,
      email: user.newUserEmail,
      role: 2,
      json: { dimensions: ['sicherheit', 'effizienz', 'recht', 'vertrauen'] }
    })
    return newUser
  },
  updateUser({ commit, dispatch }, { user, field, value }) {
    commit('updateUser', { user, field, value })
    dispatch('autoSave', null, { root: true })
    return api.updateEntry('users', Number(user.id), {
      [field]: value
    })
  }
}

export const mutations = {
  set(state, users) {
    state.list = users
  },
  emptyList(state) {
    state.list = []
  },
  updateUser(state, { user, field, value }) {
    const index = state.list.findIndex((usr) => usr.id === user.id)
    state.list[index][field] = value
  }
}

export const getters = {
  list: (state) => {
    return state.list
  },

  count: (state) => state.list.length
}
