var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticClass:"blue-grey darken-4"},[_c('v-navigation-drawer',{attrs:{"app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{staticClass:"pt-0",attrs:{"two-line":""}},[_c('v-list-tile',[_c('v-list-tile-content',[_c('v-list-tile-sub-title',[_vm._v("Projekt")]),_vm._v(" "),(_vm.project)?_c('v-list-tile-title',[_vm._v(_vm._s(_vm.project.acronym ? _vm.project.acronym : _vm.project.title))]):_vm._e()],1),_vm._v(" "),_c('v-list-tile-action',[_c('v-btn',{staticClass:"common-button primary pl-2 pr-2",staticStyle:{"font-size":"13px"},attrs:{"flat":"","small":"","nuxt":"","to":{ name: 'dashboard' }}},[_vm._v("Zum Dashboard  "),_c('v-icon',{attrs:{"small":""}},[_vm._v("arrow_forward")])],1)],1)],1)],1),_vm._v(" "),_c('v-list',{staticClass:"pt-0 pb-0",attrs:{"three-line":""}},[_c('v-list-tile',{staticClass:"grey lighten-4"},[_c('v-list-tile-content',[_c('v-list-tile-sub-title',[_vm._v("Projektgegenstand "),_c('nuxt-link',{staticStyle:{"font-weight":"normal"},attrs:{"to":{ name: 'projects-id-analysis' }}},[_vm._v("(Auswahl ändern)")])],1),_vm._v(" "),_c('v-list-tile-sub-title',{staticClass:"subheading font-weight-bold grey--text text--darken-3"},[_vm._v(_vm._s(_vm.currentSolution && _vm.currentSolution.label
                ? _vm.currentSolution.label
                : ''))])],1)],1),_vm._v(" "),_c('v-list-tile',{staticClass:"grey lighten-4"},[_c('v-list-tile-content',[_c('v-list-tile-sub-title',[_vm._v("Szenario "),_c('nuxt-link',{staticStyle:{"font-weight":"normal"},attrs:{"to":{ name: 'projects-id-analysis-solution' }}},[_vm._v("(Auswahl ändern)")])],1),_vm._v(" "),_c('v-list-tile-sub-title',{staticClass:"subheading font-weight-bold grey--text text--darken-3"},[_vm._v(_vm._s(_vm.currentScenario && _vm.currentScenario.label
                ? _vm.currentScenario.label
                : ''))])],1)],1)],1),_vm._v(" "),_c('v-list',{staticClass:"pt-0"},[_c('v-subheader',[_vm._v("Analyse")]),_vm._v(" "),_c('v-list-tile',{attrs:{"exact":"","to":{ name: 'projects-id-analysis-solution-scenario' }}},[_c('v-list-tile-action',[_c('v-icon',[_vm._v("dashboard")])],1),_vm._v(" "),_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v("Übersicht")])],1)],1),_vm._v(" "),_vm._l((_vm.items),function(item){return _c('v-list-group',{key:item.title,attrs:{"prepend-icon":item.icon,"no-action":"","group":item.group},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}},[_c('v-list-tile',{staticClass:"tile-two-line-height",attrs:{"slot":"activator"},slot:"activator"},[_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v(_vm._s(item.title))])],1)],1),_vm._v(" "),_vm._l((item.items),function(subItem){return _c('v-list-tile',{key:subItem.title,attrs:{"to":{
            name: subItem.to,
            params: { id: _vm.$route.params.id, solution: _vm.$route.params.solution }
          }}},[_c('v-list-tile-title',[_vm._v(_vm._s(subItem.title))])],1)})],2)})],2)],1),_vm._v(" "),_c('v-toolbar',{attrs:{"dark":"","app":"","flat":"","color":"blue-grey darken-4","extended":"","extension-height":"7"}},[_c('v-progress-linear',{attrs:{"slot":"extension","color":"blue-grey darken-2"},slot:"extension",model:{value:(_vm.progressValue),callback:function ($$v) {_vm.progressValue=$$v},expression:"progressValue"}}),_vm._v(" "),_c('v-toolbar-side-icon',{on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-bottom-nav',{staticClass:"elevation-0",staticStyle:{"max-width":"600px"},attrs:{"active":"project-analysis","color":"transparent","value":true,"height":"64px"}},[_c('v-btn',{attrs:{"dark":"","nuxt":"","value":"project-configuration","to":{
          name: 'projects-id-configuration-general',
          params: { id: _vm.$route.params.id }
        }}},[_c('span',[_vm._v("Konfiguration")]),_vm._v(" "),_c('v-icon',[_vm._v("settings_applications")])],1),_vm._v(" "),_c('v-btn',{attrs:{"dark":"","nuxt":"","value":"project-assessment","to":{
          name: 'projects-id-assessment',
          params: { id: _vm.$route.params.id }
        }}},[_c('span',[_vm._v("Erhebung")]),_vm._v(" "),_c('v-icon',[_vm._v("assignment")])],1),_vm._v(" "),_c('v-btn',{attrs:{"dark":"","nuxt":"","value":"project-analysis","to":{
          name: 'projects-id-analysis',
          params: { id: _vm.$route.params.id }
        }}},[_c('span',[_vm._v("Analyse")]),_vm._v(" "),_c('v-icon',[_vm._v("insert_chart")])],1)],1),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","lazy":""}},[_c('v-btn',{attrs:{"slot":"activator","icon":"","dark":""},slot:"activator"},[_c('v-icon',[_vm._v("mail")])],1),_vm._v(" "),_c('v-card',{attrs:{"width":"500","height":"600"}},[_c('iframe',{staticStyle:{"border":"0","height":"100%","width":"100%"},attrs:{"id":"mattermost-iframe","src":"https://chat.tool.4d-sicherheit.de"}})])],1),_vm._v(" "),_c('div',{staticClass:"blue-grey--text text--lighten-2",staticStyle:{"width":"215px","text-align":"right"}},[(_vm.loading)?_c('span',[_vm._v("Änderungen speichern...")]):_c('span',[_vm._v("Alle Änderungen gespeichert.")])]),_vm._v(" "),_c('v-btn',{attrs:{"icon":"","flat":"","loading":_vm.loading,"disabled":_vm.loading},nativeOn:{"click":function($event){_vm.loader = 'loading'}}},[_c('v-icon',[_vm._v("save")])],1)],1),_vm._v(" "),_c('v-content',[_c('nuxt')],1),_vm._v(" "),_c('div',[_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300","lazy":""},model:{value:(_vm.loadingModal),callback:function ($$v) {_vm.loadingModal=$$v},expression:"loadingModal"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v("\n          Analyse wird geladen\n          "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1),_vm._v(" "),_c('communication-tool'),_vm._v(" "),_c('confirm',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }