//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from '~/plugins/api'
import communicationTool from '~/components/communicationTool'

export default {
  components: {
    communicationTool
  },
  data() {
    return {
      drawer: true,
      progressValue: 30,
      loader: null,
      loading: false,
      sortBy: 'Themen',
      menu: false,
      notificationsMenu: false,
      items: [],
      menuItemsTemp: null
    }
  },
  /* beforeMount () {
    this.$socket.on('new_answer', answer => {
      this.$store.commit('criteria/update', {
        answer: answer,
        solutionId: this.$route.params.solution
      })
    })
  }, */
  computed: {
    saving() {
      return this.$store.state.saving
    },
    criteria() {
      return this.$store.state.criteria
    },
    menuItems() {
      return this.$store.getters['projectAssessmentNav/list']
    },
    solutions() {
      return this.$store.getters['solutions/list']
    },
    currentSolution() {
      return this.$store.getters['solutions/currentSolution']
    },
    project() {
      return this.$store.getters['projects/currentProject']
    },
    users() {
      return this.$store.getters['users/list']
    }
  },
  mounted() {
    this.$root.$confirm = this.$refs.confirm.open
  },
  async beforeMount() {
    if (!this.project || this.project.id !== this.$route.params.id) {
      this.loadProject()
    }
    /* let userDimensions = this.$auth.user.json.dimensions

    let sicherheitQuery = 'sicherheit: dimension (id: 1) { ...allCriteriaOfDimension }'
    let effizienzQuery = 'effizienz: dimension (id: 2) { ...allCriteriaOfDimension }'
    let rechtQuery = 'recht: dimension (id: 3) { ...allCriteriaOfDimension }'
    let vertrauenQuery = 'vertrauen: dimension (id: 4) { ...allCriteriaOfDimension }'

    let userSpecificQuery = ''
    if (userDimensions.includes('sicherheit')) userSpecificQuery = userSpecificQuery + ' ' + sicherheitQuery
    if (userDimensions.includes('effizienz')) userSpecificQuery = userSpecificQuery + ' ' + effizienzQuery
    if (userDimensions.includes('recht')) userSpecificQuery = userSpecificQuery + ' ' + rechtQuery
    if (userDimensions.includes('vertrauen')) userSpecificQuery = userSpecificQuery + ' ' + vertrauenQuery

    this.$store.commit('projectAssessmentNav/emptyList')
    const response = await api.request('post', '/graphql', {
      data: {
        query: `
          query {
            ${userSpecificQuery}
          }

          fragment allCriteriaOfDimension on Dimension {
            id
            title: label
            icon
            items: criteria {
              id
              to: name
              title: label
              items: subcriteria {
                id
                name
                title: label
              }
            }
          }
          `
      }
    })

    await this.$store.dispatch('solutions/get', this.$route.params.id)

    this.menuItemsTemp = [response.data.sicherheit, response.data.effizienz, response.data.recht, response.data.vertrauen].filter(Boolean) */
    this.$store.dispatch('solutions/getById', this.$route.params.solution)
    this.$store.commit('projectAssessmentNav/emptyList')
    /* const response = await api.request('post', '/graphql', {
      data: {
        query: `
          query {
            dimensions {
              id
              title: label
              icon
              items: criteria {
                id
                to: name
                title: label
                items: subcriteria {
                  id
                  name
                  title: label
                }
              }
            }
          }
        `
      }
    })
    await this.$store.dispatch('solutions/get', this.$route.params.id)
    this.menuItemsTemp = response.data.dimensions

    this.iterator()
    this.$store.commit('projectAssessmentNav/add', this.menuItemsTemp) */
    const response = await api.request('post', '/graphql', {
      data: {
        query: `
          query {
            answers(where: { solution: "${this.$route.params.solution}", user: "${this.$auth.user.id}" }) {
              subcriterion {
                id
              }
            }
            solution(id: "${this.$route.params.solution}") {
              id
              subcriteria {
                id
                name
                label
                criterion {
                  id
                  name
                  label
                }
                dimension {
                  id
                  label
                  name
                  icon
                }
              }
            }
          }
        `
      }
    })

    const menu = {
      dimensions: []
    }

    response.data.solution.subcriteria.forEach((subcriterion) => {
      // console.log(subcriterion)
      if (subcriterion.dimension) {
        menu.dimensions.push({
          id: subcriterion.dimension.id,
          name: subcriterion.dimension.name,
          label: subcriterion.dimension.label,
          icon: subcriterion.dimension.icon,
          criteria: [
            {
              id: subcriterion.criterion.id,
              name: subcriterion.criterion.name,
              label: subcriterion.criterion.label,
              subcriteria: [
                {
                  id: subcriterion.id,
                  name: subcriterion.name,
                  label: subcriterion.label
                }
              ]
            }
          ]
        })
      }
    })

    const menu2 = []
    const idSeen = []

    menu.dimensions.forEach((mdimension) => {
      if (!idSeen.includes(mdimension.id)) {
        const dimensions = menu.dimensions.filter(
          (dimension) => dimension.id === mdimension.id
        )
        const criteria = []
        dimensions.forEach((dim) => criteria.push(...dim.criteria))
        menu2.push({
          id: mdimension.id,
          name: mdimension.name,
          label: mdimension.label,
          icon: mdimension.icon,
          criteria
        })
        idSeen.push(mdimension.id)
      }
    })

    menu2.forEach((mdimension) => {
      const arrayHashmap = mdimension.criteria.reduce((obj, item) => {
        obj[item.id]
          ? obj[item.id].subcriteria.push(...item.subcriteria)
          : (obj[item.id] = { ...item })
        return obj
      }, {})

      mdimension.criteria = Object.keys(arrayHashmap).map(
        (id) => arrayHashmap[id]
      )
    })

    const answers = response.data.answers.map((a) => a.subcriterion.id)

    menu2.forEach((mdimension) => {
      let numberOfSubcriteria = 0
      let numberOfAnswers = 0

      mdimension.criteria.forEach((criterion) => {
        numberOfSubcriteria = numberOfSubcriteria + criterion.subcriteria.length
        if (response.data.answers) {
          criterion.subcriteria.forEach((subcriterion) => {
            if (answers.includes(subcriterion.id)) numberOfAnswers++
          })
        }
      })
      mdimension.numberOfSubcriteria = numberOfSubcriteria
      mdimension.numberOfAnswers = numberOfAnswers
    })

    if (this.$auth.user) {
      if (this.$auth.user.role.id === 1)
        this.$store.commit('projectAssessmentNav/add', menu2)
      else
        this.$store.commit(
          'projectAssessmentNav/add',
          menu2.filter((topic) =>
            this.$auth.user.json.values.includes(topic.label)
          )
        )
    }

    await this.$store.dispatch('institutions/get', this.$route.params.id) // for communication tool
    await this.$store.dispatch('users/get')
  },
  methods: {
    startSaving() {
      this.$store.dispatch('autoSave')
    },
    getGroup(navItem) {
      const array = []
      navItem.items.forEach((navItem) => {
        array.push(navItem.to)
      })
      return '/' + array.join('|')
    },
    async loadProject() {
      if (this.$route.params.id) {
        try {
          await this.$store.dispatch(
            'projects/loadProjectById',
            this.$route.params.id
          )
          setTimeout(() => (this.loadingModal = false), 1000)
        } catch (err) {
          alert(
            'Fehler beim Laden: Projekt nicht gefunden oder Sie haben keine Berechtigung. Sie werden zum Dashboard weitergeleitet.'
          )
          this.$router.push({ name: 'dashboard' })
        }
      }
    },
    iterator(menuItems, solutions) {
      const filteredSolutions = this.solutions.filter(
        (solution) =>
          Number(solution.id) === Number(this.$route.params.solution)
      )
      const filteredSolutionsArray = []
      filteredSolutions[0].subcriteria.forEach((subcriteria) =>
        filteredSolutionsArray.push(Number(subcriteria.id))
      )

      this.menuItemsTemp.forEach((menuItem) => {
        menuItem.items.forEach((criterion) => {
          criterion.items.forEach((subcriterion) => {
            subcriterion.show = false
            criterion.show = false
          })
        })
      })

      this.menuItemsTemp.forEach((menuItem) => {
        menuItem.items.forEach((criterion) => {
          criterion.items.forEach((subcriterion) => {
            if (filteredSolutionsArray.includes(Number(subcriterion.id))) {
              subcriterion.show = true
              criterion.show = true
            }
          })
        })
      })
      this.$store.commit('projectAssessmentNav/add', this.menuItemsTemp)
    }
  }
}
