//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import communicationTool from '~/components/communicationTool'

export default {
  components: {
    communicationTool
  },
  data() {
    return {
      drawer: true,
      loader: null,
      loading: false,
      loadingModal: true,
      progressValue: 27,
      items: [
        {
          icon: 'apps',
          title: 'Allgemeine Projektangaben',
          subtitle: 'In Bearbeitung',
          to: 'projects-id-configuration-general'
        },
        {
          icon: 'ballot',
          title: 'Projektthemen',
          to: 'projects-id-configuration-topics'
        },
        {
          icon: 'supervisor_account',
          title: 'Projektteilnehmer',
          to: 'projects-id-configuration-users'
        },
        {
          icon: 'compare_arrows',
          title: 'Projektgegenstände',
          subtitle: 'In Bearbeitung',
          to: 'projects-id-configuration-solutions'
        },
        {
          icon: 'receipt',
          title: 'Konfigurationsbericht',
          to: 'projects-id-configuration-report',
          disabled: true
        }
      ],
      items2: [
        {
          icon: 'scatter_plot',
          title: 'Szenarien-Editor',
          to: 'projects-id-configuration-scenarios'
        },
        {
          icon: 'multiline_chart',
          title: 'Risiko-Analyse',
          to: 'projects-id-configuration-risk-analysis',
          disabled: true
        },
        {
          icon: 'work',
          title: 'Notizen und Moderationswerkzeuge',
          to: 'projects-id-configuration-notes-moderation'
        },
        {
          icon: 'account_tree',
          title: 'Kriterien-Editor',
          to: 'projects-id-configuration-criteria-editor'
        },
        {
          icon: 'work',
          title: 'Projektbudgetverwaltung',
          subtitle: 'In Bearbeitung',
          to: 'projects-id-configuration-budgetmanager'
        }
      ]
    }
  },
  computed: {
    saving() {
      return this.$store.state.saving
    },
    project() {
      return this.$store.getters['projects/currentProject']
    }
  },
  beforeMount() {
    this.loadProject()
  },
  mounted() {
    this.$root.$confirm = this.$refs.confirm.open
  },
  methods: {
    startSaving() {
      this.$store.dispatch('autoSave')
    },
    async loadProject() {
      if (this.$route.params.id) {
        try {
          setTimeout(() => (this.loadingModal = false), 1000)
          await this.$store.dispatch(
            'projects/loadProjectById',
            this.$route.params.id
          )
          await this.$store.dispatch('solutions/get', this.$route.params.id)
          await this.$store.dispatch('users/get')
          return this.$store.dispatch('scenarios/get', this.$route.params.id)
        } catch (err) {
          console.error('err', err)
          alert(
            'Fehler beim Laden: Projekt nicht gefunden oder Sie haben keine Berechtigung. Sie werden zum Dashboard weitergeleitet.'
          )
        }
      }
    }
  }
}
