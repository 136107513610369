import api from '~/plugins/api'

export const state = () => ({
  list: []
})

export const actions = {
  async create({ commit }, payload) {
    const newRoom = await api.createEntry('rooms', payload)
    return newRoom
  },

  async get({ commit, rootState }) {
    const response = await api.request('post', '/graphql', {
      data: {
        query: `
          query {
            rooms1: rooms(where: { createdBy: "${rootState.auth.user.id}" }) {
              ...rooms
            }
            rooms2: rooms(where: { partnerId: "${rootState.auth.user.id}" }) {
              ...rooms
            }
          }

          fragment rooms on Room {
            id
            subject
            type
            solution {
              id
              label
            }
            subcriterion {
              id
              name
              label
              criterion {
                id
                name
                label
              }
            }
            project {
              id
              acronym
            },
            messages (limit: 1, sort: "created_at:desc") {
              id
              createdAt
            }
          }
          `
      }
    })
    const rooms = [...response.data.rooms1, ...response.data.rooms2]
    rooms.forEach((room) => {
      commit('add', {
        id: room.id,
        ...room
      })
    })
  }
}

export const mutations = {
  add(state, room) {
    state.list.push(room)
  },
  emptyList(state) {
    state.list = []
  }
}

export const getters = {
  list: (state) => {
    return state.list
  }
}
