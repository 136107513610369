import api from '~/plugins/api'

export const state = () => ({
  simulationMode: false
})

export const actions = {
  async get({ commit }) {
    const solutionsResponse = await api.request('post', '/graphql', {
      data: {
        query: `
          query {
            solutions (where: { scenario: 1}, sort: "id") {
              id
              label
              subcriteria {
                id
              }
            }
          }
          `
      }
    })
    commit('set', solutionsResponse.data.solutions)
  },
  startSimulationMode({ commit }) {
    commit('startSimulationMode')
  },
  endSimulationMode({ commit }) {
    commit('endSimulationMode')
  }
}

export const mutations = {
  set(state, solutions) {
    state.list = solutions
  },
  emptyList(state) {
    state.list = []
  },
  startSimulationMode(state) {
    state.simulationMode = true
  },
  endSimulationMode(state) {
    state.simulationMode = false
  }
}

export const getters = {
  simulationMode: (state) => {
    return state.simulationMode
  }
}
