import api from '~/plugins/api'

export const state = () => ({
  list: [],
  currentProject: null
})

export const actions = {
  async get({ commit }) {
    try {
      const response = await api.request('post', '/graphql', {
        data: {
          query: `
            query {
              projects (sort: "id") {
                id
                title
                acronym
                projectType
                currentPhase
                phases
                participants
                projectLeader {
                  id
                }
              }
            }
            `
        }
      })
      commit('emptyList')
      response.data.projects.forEach((project) => {
        commit('add', project)
      })
    } catch (err) {
      console.error('Fehler beim Laden der Projekte', err)
    }
  },
  async loadProjectById({ commit }, projectId) {
    try {
      const response = await api.request('post', '/graphql', {
        data: {
          query: `
            query {
              project (id: "${projectId}") {
                id
                title
                acronym
                projectDescription
                projectType
                currentPhase
                phases
                projectStartDate
                projectEndDate
                privacyChecked
                needForAction
                frameworkForActionAvailable
                frameworkForAction
                frameworkForActionExistsForMultipleNeedForActions
                applicationContexts
                doesProjectBudgetExist
                projectBudget
                useBudgetManagement
                currentStep
                subjectOfProject
                projectLeader {
                  id
                  username
                  firstName
                  lastName
                  institution {
                    id
                    name
                  }
                  role {
                    id
                    name
                  }
                  json
                }
                participants
                topics
              }
            }
            `
        }
      })
      commit('addCurrentProject', response.data.project)
      // setTimeout(() => (this.loadingModal = false), 1000)
    } catch (err) {
      console.log('error loading project', err)
      // alert('Fehler beim Laden: Projekt nicht gefunden oder Sie haben keine Berechtigung.')
      // this.$router.push({ name: 'dashboard' })
    }
  },
  updateProject({ commit, state, dispatch }, { field, value }) {
    api.updateEntry('projects', Number(state.currentProject.id), {
      [field]: value
    })
    console.info('saving...', { field, value })
    dispatch('autoSave', null, { root: true })
    commit('updateProject', { field, value })
  }
}

export const mutations = {
  add(state, project) {
    project.currentPhase = currentPhaseDict[project.currentPhase]
    state.list.push(project)
  },
  emptyList(state) {
    state.list = []
  },
  addCurrentProject(state, project) {
    project.currentPhaseLabel = currentPhaseDict[project.currentPhase]
    state.currentProject = project
  },
  updatePhases(state, projectId) {
    state.currentProject.phases.analysis.status.done = true
    api.updateEntry('projects', projectId, {
      phases: state.currentProject.phases,
      currentPhase: 'analysis'
    })
  },
  updateProject(state, { field, value }) {
    Object.assign(state.currentProject, {
      [field]: value
    })
  }
}

export const getters = {
  list: (state) => {
    return state.list
  },
  currentProject: (state) => {
    return state.currentProject
  }
}

const currentPhaseDict = {
  configuration: 'Konfiguration',
  assessment: 'Erhebung',
  analysis: 'Analyse',
  null: '-'
}
