//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Avataaars from 'vuejs-avataaars'
import { mapMutations } from 'vuex'

export default {
  components: {
    Avataaars
  },
  methods: {
    ...mapMutations({
      logout: 'auth/logout'
    })
  },
  data() {
    return {
      buildNumber: process.env.build,
      drawer: true,
      items: [
        { icon: 'apps', title: 'Startseite', to: 'index' },
        { icon: 'dashboard', title: 'Dashboard', to: 'dashboard' },
        { icon: 'folder', title: 'Projekte', to: 'projects' },
        { icon: 'mail', title: 'Nachrichten', to: 'messages' },
        { icon: 'menu_book', title: 'Bibliothek', to: 'library' },
        { icon: 'account_circle', title: 'Profil und Konto', to: 'account' },
        {
          icon: 'supervised_user_circle',
          title: 'Administration',
          to: 'admin'
        },
        { icon: 'help', title: 'Hilfe', to: 'help' },
        { icon: 'assignment', title: 'Impressum', to: 'imprint' }
      ]
    }
  },
  mounted() {
    this.$root.$confirm = this.$refs.confirm.open
  }
}
