export const state = () => ({
  saving: false
})

export const mutations = {
  startSaving(state) {
    state.saving = true
  },
  stopSaving(state) {
    state.saving = false
  }
}

export const actions = {
  startSaving({ commit }) {
    commit('startSaving')
  },
  stopSaving({ commit }) {
    commit('stopSaving')
  },
  autoSave({ commit }) {
    commit('startSaving')
    setTimeout(() => {
      commit('stopSaving')
    }, 1000)
  }
}
