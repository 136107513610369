import Vue from 'vue'
import Chart from 'chart.js'

Vue.filter('ellipsis', (value, length) => {
  if (value.length > length) return value.substring(0, length) + '...'
  else return value
})

Chart.defaults.global.defaultFontSize = 14
Chart.defaults.global.defaultFontFamily = 'Camphor, "Roboto", sans-serif'
