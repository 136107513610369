import api from '~/plugins/api'

export const state = () => ({
  list: [],
  currentSolution: null
})

export const actions = {
  async get({ commit }, projectId) {
    const solutionsResponse = await api.request('post', '/graphql', {
      data: {
        query: `
          query {
            solutions (where: { project: "${projectId}" }, sort: "id") {
              id
              label
              subcriteria {
                id
              }
            }
          }
          `
      }
    })
    commit('set', solutionsResponse.data.solutions)
  },
  async getById({ commit }, solutionId) {
    const solutionsResponse = await api.request('post', '/graphql', {
      data: {
        query: `
          query {
            solution (id: "${solutionId}") {
              id
              label
              acronym
              description
              developmentStatus
              solutionType
              mainUsePurpose
              otherUsePurposes
              applicationContexts
              topics
              responsibleOffices
              involvedOrganisations
              locationsAndTimes
              staffRequiredToOperate
              staff
              targetExists
              targetGroups
              areThirdPartiesAffected
              affectedThirdParties
              functionalities
              isSystemOfSystems
              systemOfSystemsDescription
              neighboringSystems
              technicalDescription
              legalBasis
              processVisualisation {
                id
                url
              }
              legalBasisFiles {
                id
                url
              }
              centralFunctions
            }
          }
          `
      }
    })
    commit('setCurrentSolution', solutionsResponse.data.solution)
  },
  updateSolution({ commit, state, dispatch }, { field, value }) {
    dispatch('autoSave', null, { root: true })
    commit('updateSolution', { field, value })
    return api.updateEntry('solutions', Number(state.currentSolution.id), {
      [field]: value
    })
  },
  async addDuplicate({ dispatch, rootState }, originalId) {
    const solutionCopy = await api.getEntry('solutions', originalId)
    delete solutionCopy.id
    solutionCopy.label = solutionCopy.label + ' (Duplikat)'
    const duplicateSolution = await api.createEntry('solutions', solutionCopy)
    dispatch('autoSave', null, { root: true })
    dispatch('get', rootState.projects.currentProject.id)
    return duplicateSolution
  },
  async addNewSolution({ dispatch, rootState }, solutionType) {
    const newSolution = await api.createEntry('solutions', {
      project: rootState.projects.currentProject.id,
      solutionType: solutionType || 'existingSolution'
    })
    dispatch('autoSave', null, { root: true })
    dispatch('get', rootState.projects.currentProject.id)
    return newSolution
  },
  async deleteSolution({ dispatch, rootState }, index) {
    await api.deleteEntry('solutions', Number(index))
    dispatch('autoSave', null, { root: true })
    dispatch('get', rootState.projects.currentProject.id)
  }
}

export const mutations = {
  set(state, solutions) {
    state.list = solutions
  },
  setCurrentSolution(state, solution) {
    state.currentSolution = solution
  },
  emptyList(state) {
    state.list = []
  },
  updateSolution(state, { field, value }) {
    Object.assign(state.currentSolution, {
      [field]: value
    })
  }
}

export const getters = {
  list: (state) => {
    return state.list
  },
  currentSolution: (state) => {
    return state.currentSolution
  }
}
